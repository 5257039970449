<template>
    <div
        v-if="dialog"
        class="review-modal"
        @click.self="close()"
    >
        <div class="review-modal__dialog">
            <header class="review-modal__header">
                <h2>Reviews</h2>
                <span
                    class="review-modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </header>
            <div class="review-modal__reviews-list">
                <WriterTabReviews
                    :writer="writerData"
                    :writer-id="writerId"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Api from '@/helpers/api/index.js'

import CloseIcon from 'mdi-vue/Close.vue';
import WriterTabReviews from '@/components/writers/WriterTabReviews.vue'
import { eventBus } from '@/helpers/event-bus/'

export default {
    name: 'ReviewModal',
    components: {
        CloseIcon,
        WriterTabReviews
    },
    data: () => ({
        dialog: false,
        writerData: null,
        writerId: null
    }),
    methods: {
        async open(writerId) {
            this.writerId = writerId
            try {
                this.dialog = true
                const { data } = await Api.get('/api/writer/fetch', { id: writerId })
                this.writerData = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        close() {
            this.dialog = false
        }
    }
}
</script>

<style lang="scss">
.review-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &__dialog {
        max-width: 985px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        //position: fixed;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
    }
    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 30px 30px 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $second-color;
        cursor: pointer;
    }
    &__reviews-list{
        max-height: 550px;
        min-height: 550px;
        overflow-y: scroll;
    }
}
</style>
