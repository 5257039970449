<template>
    <div class="select-tab">
        <div
            v-click-outside="clickOutside"
            class="form-select-tab"
            :class="{ 'grey-border' : openListItem }"
        >
            <fieldset :class="openListItem ? 'form-select-tab__fieldset active' : 'form-select-tab__fieldset'">
                <div class="form-select-tab__input-field">
                    <span
                        :class="openListItem ? 'select-tab-open' : 'select-tab-close'"
                        @click="openListItem = !openListItem"
                    />
                    <!--                    <component-->
                    <!--                        :is="dataSelectedTab.selectedIcon"-->
                    <!--                        class="form-input-tab__prepend-icon"-->
                    <!--                    />-->
                    <input
                        class="form-select-tab__input"
                        :value="setValue"
                        type="text"
                        :name="name"
                        autocomplete="off"
                        :placeholder="name"
                        :disabled="disabled"
                        readonly
                        @click.self="openListItem = !openListItem"
                        @focus="$event.target.select()"
                        @input="onInputChanged($event)"
                    >
                    <span
                        v-if="dataSelectedTab.selectedCounter !== false"
                        class="form-select-tab__counter form-select-tab__counter--base"
                    >
                        {{ dataSelectedTab.selectedCounter }}
                    </span>
                    <!--                    <span-->
                    <!--                        :class="{'active': openListItem}"-->
                    <!--                        class="form-select-tab__arrow"-->
                    <!--                        @click="openListItem = !openListItem"-->
                    <!--                    >-->
                    <!--                        <chevron-down />-->
                    <!--                    </span>-->
                </div>
                <Transition name="slide-fade">
                    <ul
                        v-if="openListItem"
                        class="form-select-tab__list"
                    >
                        <span
                            class="form-select-tab__list-title"
                            @click.stop="openListItem = false"
                        >
                            Categories
                        </span>
                        <li
                            v-for="(item, index) in filteredList"
                            :key="index"
                            class="form-select-tab__list-item"
                            :class="{'active-tab-link': $route.name === item.value}"
                            @click="selectItem(item.value, item.icon, item.counter)"
                        >
                            <span class="form-select-tab__list-item-icon">
                                <component :is="item.icon" />
                                <span class="form-select-tab__list-item-text">{{ item.text }}</span>
                            </span>
                            <span
                                v-if="item.counter !== false"
                                class="form-select-tab__counter"
                            >
                                {{ item.counter }}
                                <span
                                    v-if="item.counterUndread > 0"
                                    class="bubble--unread bubble--unread-small"
                                >
                                    {{ item.counterUndread }}
                                </span>
                            </span>
                        </li>
                    </ul>
                </Transition>
            </fieldset>
        </div>
    </div>
</template>

<script>
// import ClickOutside from 'vue-click-outside'
import ChevronDown from 'mdi-vue/ChevronDown'
import List from '@/components/icons/List'
import PaperClip from '@/components/icons/PaperClip'
import Mail from '@/components/icons/Mail'
import Support from '@/components/icons/Support'
import AboutWriter from '@/components/icons/WriterTabs/AboutWriter'
import Reviews from '@/components/icons/WriterTabs/Reviews'

export default {
    name: 'SelectTabs',
    components: {
        ChevronDown,
        List,
        PaperClip,
        Mail,
        Support,
        AboutWriter,
        Reviews
    },
    // directives: {
    //     ClickOutside
    // },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        valueSelect: { type: [String, Number] },
        items: { type: Array },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            search: '',
            dataSelectedTab: {
                selectedIcon: List,
                selectedCounter: false
            }
        };
    },
    computed: {
        setValue() {
            const isExist = this.items.some((el) => el.value === this.search);
            if (isExist) {
                this.$emit('input', this.search);
                return this.items.find((element) => element.value === this.search).text;
            }
            if (this.escape) {
                return ''
            }
            return this.search;
        },
        filteredList() {
            if (this.search === this.value) {
                return this.items
            }
            return this.items.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
            } else {
                this.search = value
            }
        },
        $route(to) {
            this.search = to.name
            this.items.forEach((item) => {
                if (item.value === this.search) {
                    this.dataSelectedTab.selectedCounter = item.counter
                    this.dataSelectedTab.selectedIcon = item.icon
                }
            })
        }
    },
    created() {
        this.setInitValue();
        this.search = this.$route.name
        this.items.forEach((item) => {
            if (item.value === this.search) {
                this.dataSelectedTab.selectedCounter = item.counter
            }
        })
    },
    methods: {
        selectItem(value, icon, counter) {
            this.dataSelectedTab = {
                selectedIcon: icon,
                selectedCounter: counter
            }
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            this.search = this.valueSelect ? this.valueSelect : '';
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss">
.form-select-tab {
    outline: none;
    box-shadow: inset 0px -1px 0 0 #e7ecf4;
    border-bottom: 2px solid #EFEFEF;
    max-height: 52px!important;
    width: 100%;

    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        //border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }
    &__fieldset {
        position: relative;
        border: none ;
        outline: none;
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: rgba($main-color, 0.1);
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 12px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: 600;
        max-height: 48px;
        height: 48px;
        color: #435A7D;
    }
    &__input-field {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        svg {
            margin-left: 10px;
            margin-right: 0;
            width: 35px;
        }
        .active {
            background-color: #fff;
            svg {
                transform: rotate(-180deg);
            }
        }
    }
    //&__arrow{
    //    margin-right: 15px;
    //}
    &__list {
        position: fixed;
        overflow: hidden;
        background-color: $white;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overscroll-behavior: contain;
        z-index: 100;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        .active-tab-link{
            background: #F2F6FF;
        }
    }
    &__list-title {
        height: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #111720;
        padding: 35px 35px 35px 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e7ecf4;
        margin-bottom: 20px;
        position: relative;
        &::before{
            content: '';
            width: 20px;
            height: 15px;
            background: url('~@/assets/img/svg/arrow.svg') no-repeat;
            position: absolute;
            left: 25px;
        }
    }
    &__counter{
        position: relative;
        font-weight: bold;
        color: #fff;
        background-color: $main-color;
        padding: 0px 7px;
        border-radius: 50px;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
        height: 21px;
        &--base {
            margin-right: 15px;
        }
    }
    &__list-item {
        padding: 10px 15px;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 15px 1px 15px;
        &:hover {
            background-color: lightgrey;
        }
    }
    &__list-item-icon {
        //width: 35px;
        display: flex;
        align-items: center;
        color: #435A7D;
    }
    &__list-item-text {
        width: 110px;
        white-space: nowrap;
        font-weight: 600;
    }
}

.select-tab{
    display: none;
    @media screen and (max-width: 992px) {
        display: flex;
    }
}

.select-tab-close{
    cursor: pointer;
    border-right: 2px solid #EAEAEA;
    &:after {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        margin: 0 10px;
        background: url("~@/assets/img/svg/menu-preset.svg") no-repeat center;
        transform: rotate(0deg);
        transition: .5s;
    }
}

.select-tab-open{
    cursor: pointer;
    border-right: 2px solid #EAEAEA;
    &:after {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        margin: 0 10px;
        background: url("~@/assets/img/svg/menu-preset.svg") no-repeat center;
        transform: rotate(90deg);
        transition: .5s;
    }
}

</style>
