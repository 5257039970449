<template>
    <div class="express-order__loading">
        <div class="express-order__loading-header">
            <p class="express-order__loading-title">
                Searching for writers ...
            </p>
            <div class="express-order__loading-time">
                {{ formattedTime }}
            </div>
            <p class="express-order__loading-results">
                {{ resultsCount }} {{ resultsCount === 1 ? 'offer' : 'offers' }}
            </p>
        </div>
        <div class="express-order__loading-bar" />

        <p class="express-order__loading-counter">
            <span>
                <b>{{ writersCounter.all || 0 }}</b> online
            </span>
        </p>
        <p class="express-order__loading-counter">
            <span v-if="writersCounter.count > 0">
                <b>{{ writersCounter.count }}</b> viewing your project
            </span>
        </p>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'OrderExpressLoading',
    props: {
        resultsCount: {
            type: [String, Number],
            required: false,
            default: 0
        },
        orderCreatedDate: {
            type: String,
            required: true
        },
        writersCounter: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            time: 0
        }
    },
    computed: {
        formattedTime() {
            const d = Math.floor(this.time / (3600 * 24));
            // eslint-disable-next-line no-mixed-operators
            const h = Math.floor(this.time % (3600 * 24) / 3600);
            // eslint-disable-next-line no-mixed-operators
            const m = Math.floor(this.time % 3600 / 60);
            const s = Math.floor(this.time % 60);

            const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
            const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
            const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
            const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
            return dDisplay + hDisplay + mDisplay + sDisplay;
        }
    },
    created() {
        setInterval(() => {
            const start = moment(this.orderCreatedDate)
            const now = moment()
            this.time = moment.duration(now.diff(start)).asSeconds().toFixed(0)
        }, 1000);
    }
}
</script>
