import {
    requiredIf,
    minLength,
    maxLength,
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    form: {
        delivery: {
            required,
            checkValue(value) {
                if (value) return true
                return false
            }
        },
        delivery_details: {
            required: requiredIf(function () {
                return !!((this.activeTab === 0 && this.form.delivery < 4))
            }),
            minLength: minLength(20),
            maxLength: maxLength(1000)
        },
        content: {
            checkValue(value) {
                if (!this.tabs[1].active) return true
                return !!value
            }
        },
        content_details: {
            required: requiredIf(function () {
                return !!((this.activeTab === 1 && this.form.content < 4))
            }),
            minLength: minLength(20),
            maxLength: maxLength(1000)
        },
        communication: {
            checkValue(value) {
                if (!this.tabs[2].active) return true
                return !!value
            }
        },
        communication_details: {
            required: requiredIf(function () {
                return !!((this.activeTab === 2 && this.form.communication < 4))
            }),
            minLength: minLength(20),
            maxLength: maxLength(1000)
        },
        website: {
            checkValue(value) {
                if (!this.tabs[3].active) return true
                return !!value
            }
        },
        website_details: {
            required: requiredIf(function () {
                return !!((this.activeTab === 3 && this.form.website < 4))
            }),
            minLength: minLength(20),
            maxLength: maxLength(1000)
        },
        support: {
            checkValue(value) {
                if (!this.tabs[4].active) return true
                return !!value
            }
        },
        support_details: {
            required: requiredIf(function () {
                return !!((this.activeTab === 4 && this.form.support < 4))
            }),
            minLength: minLength(20),
            maxLength: maxLength(1000)
        },
        message: {
            // required: requiredIf(function () {
            //     return this.activeTab === 5
            // }),
            minLength: minLength(0),
            maxLength: maxLength(1000)
        }
    }
};
